import React from "react";

function SelectBox({ name, label, onChange, options, ...otherprops }) {
  const optionValue = options;

  return (
    <div className="flex flex-col w-80 mb-4">
      <label className="text-xs font-bold text-gray-600" {...otherprops}>
        {label}
      </label>
      <select
        onChange={onChange}
        name={name}
        {...otherprops}
        className="disabled:text-gray-800 disabled:bg-gray-200 w-80 px-4 py-2 border rounded-md border-gray-400 focus:outline-none focus:border-blue-500"
      >
        {optionValue.map((option) => (
          <option value={option}>{option}</option>
        ))}
      </select>
    </div>
  );
}

export default SelectBox;
