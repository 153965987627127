import React, { useEffect } from "react";
import { getAllDocsSnap } from "../../Firebase/Firebase.utils";
import DownloadExcel from "../../components/DownloadExcel";

export default function SummaryBillings() {
  const [allBillings, setAllBillings] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [leases, setLeases] = React.useState([]);
  const [sales, setSales] = React.useState([]);
  const [activeLink, setActiveLink] = React.useState("lease");

  useEffect(() => {
    const fetchAllBillings = async () => {
      await getAllDocsSnap("billing", (data) => {
        setAllBillings(data);
        const leases = data.filter(
          (billing) => billing.billing_type === "lease"
        );
        const approvedLeases = leases.filter(
          (lease) => lease.status === "approved"
        );

        //arrange by total_area_leased
        approvedLeases.sort((a, b) => {
          return (
            parseFloat(b.total_area_leased) - parseFloat(a.total_area_leased)
          );
        });

        const approvedSales = data.filter(
          (billing) =>
            billing.billing_type === "sale" && billing.status === "approved"
        );

        setSales(approvedSales);

        setLeases(approvedLeases);
        setLoading(false);
      });
    };
    fetchAllBillings();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  console.log("sales", sales);

  const totalLeases = leases.reduce((acc, curr) => {
    return acc + parseFloat(curr.total_area_leased);
  }, 0);

  const totalLeaseAmount = leases.reduce((acc, curr) => {
    return acc + parseFloat(curr.amount);
  }, 0);

  const links = ["lease", "sale", "others"];

  const totalSales = sales.reduce((acc, curr) => {
    //check first if Nan
    if (isNaN(parseFloat(curr.lot_area))) {
      return acc;
    } else {
      return acc + parseFloat(curr.lot_area);
    }
  }, 0);

  const totalTransactionValue = sales.reduce((acc, curr) => {
    //check first if Nan
    if (isNaN(parseFloat(curr.transaction_value))) {
      return acc;
    } else {
      return acc + parseFloat(curr.transaction_value);
    }
  }, 0);

  console.log("totalTransactionValue", totalTransactionValue);

  const totalFloorArea = sales.reduce((acc, curr) => {
    //check first if nan
    if (isNaN(parseFloat(curr.floor_area))) {
      return acc;
    } else {
      return acc + parseFloat(curr.floor_area);
    }
  }, 0);

  const totalSalesAmount = sales.reduce((acc, curr) => {
    //check first if nan
    if (isNaN(parseFloat(curr.amount))) {
      return acc;
    } else {
      return acc + parseFloat(curr.amount);
    }
  }, 0);

  const mappedNoNanSales = sales.map((sale) => {
    return {
      ...sale,
      lot_area: isNaN(parseFloat(sale.lot_area))
        ? 0
        : parseFloat(sale.lot_area),
      floor_area: isNaN(parseFloat(sale.floor_area))
        ? 0
        : parseFloat(sale.floor_area),
    };
  });

  return (
    <div>
      <div className="links-container uppercase">
        {links.map((link, index) => (
          <div
            className={`link ${
              activeLink === link ? "activeLinkStyle" : "inactiveLinkStyle"
            }`}
            key={index}
            onClick={() => setActiveLink(link)}
          >
            {link}
          </div>
        ))}
      </div>

      {activeLink === "lease" && (
        <div className="mt-4">
          <div className="header">Summary of Billings Per Type</div>
          <div>
            <div>Leases for 2024</div>
            <div>
              Total Area Leased:{" "}
              {totalLeases.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}{" "}
              square meters
            </div>
            <div>
              Total Amount Billed:{" "}
              {totalLeaseAmount.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </div>

            <DownloadExcel
              data={leases}
              label="Download Excel"
              filename="Leases"
            />

            <div className="mt-4">
              <div className="flex items-center gap-4 font-bold">
                <div className="w-[300px]"> Landlord</div>
                <div className="w-[300px]"> Tenant</div>

                <div className="text-right w-[100px]">
                  Total Area Leased (sqm)
                </div>
                <div>Number of Parking Slots</div>
              </div>
              {leases.map((lease, index) => {
                return (
                  <div
                    key={index}
                    className="flex items-center gap-4 space-y-1"
                  >
                    <div className="w-[300px]"> {lease.landlord}</div>
                    <div className="w-[300px]"> {lease.tenant}</div>
                    <div className="text-right w-[100px]">
                      {parseFloat(lease.total_area_leased).toLocaleString(
                        undefined,
                        {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }
                      )}
                    </div>
                    <div>{lease.parking_slots}</div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}

      {activeLink === "sale" && (
        <div className="mt-4">
          <div className="header">Summary of Billings Per Type</div>
          <div>
            <div>Sale for 2024</div>
            <div>
              Total Lot Area Sold:{" "}
              {totalSales.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}{" "}
              square meters
            </div>
            <div>
              Total Floor Area Sold:{" "}
              {totalFloorArea.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}{" "}
              square meters
            </div>
            <div>
              Total Amount Billed:{" "}
              {totalSalesAmount.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </div>
            <div>
              Total Transaction Value:{" "}
              {totalTransactionValue.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </div>

            <DownloadExcel
              data={mappedNoNanSales}
              label="Download Excel"
              filename="Sales"
            />

            <div className="mt-4">
              <div className="flex items-center gap-4 font-bold">
                <div className="w-[300px]"> Buyer</div>
                <div className="w-[300px]"> Seller</div>
                <div className="text-right w-[100px]">Transaction Value</div>

                <div className="text-right w-[100px]">
                  Total Lot Area Sold (sqm)
                </div>
                <div>Floor Area Sold</div>
              </div>
              {mappedNoNanSales.map((sale, index) => {
                return (
                  <div
                    key={index}
                    className="flex items-center gap-4 space-y-1"
                  >
                    <div className="w-[300px]"> {sale.buyer}</div>
                    <div className="w-[300px]"> {sale.seller}</div>
                    <div className="text-right w-[100px]">
                      {parseFloat(sale.transaction_value).toLocaleString(
                        undefined,
                        {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }
                      )}
                    </div>
                    <div className="text-right w-[100px]">
                      {parseFloat(sale.lot_area).toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </div>
                    <div className="text-right w-[100px]">
                      {parseFloat(sale.floor_area).toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
