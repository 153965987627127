import React, { useEffect, useState } from "react";
import { getAllDocsSnap, setDocData } from "../../Firebase/Firebase.utils";

export default function IsAccountsPerAgent({ setViewMode }) {
  const [listings, setListings] = React.useState([]);
  const [agents, setAgents] = React.useState([]);
  const [accounts, setAccounts] = React.useState([]);
  const [isloading, setIsLoading] = React.useState(true);

  const statusPercentages = ["10%", "30%", "50%", "90%", "100%"];
  const [percentageArrays, setPercentageArrays] = useState(statusPercentages);

  useEffect(() => {
    const fetchAgents = async () => {
      await getAllDocsSnap("users", (data) => {
        const isAgents = data.filter(
          (agent) =>
            agent.department === "Investment Sales" &&
            agent.roles.includes("agent")
        );

        const filteredAgents = isAgents.filter(
          (agent) => agent.displayName !== "Henry Cabrera"
        );

        //sort agents alphabetically
        filteredAgents.sort((a, b) => {
          return a.displayName.localeCompare(b.displayName);
        });

        setAgents(filteredAgents);
      });
    };
    fetchAgents();
  }, []);

  useEffect(() => {
    const fetchAccounts = async () => {
      await getAllDocsSnap("listings", (data) => {
        setListings(data);

        console.log(data);
        setIsLoading(false);
      });
    };
    fetchAccounts();
  }, []);

  if (isloading) {
    return <div>Loading...</div>;
  }

  const totalIsListings = listings.filter((listing) =>
    agents.some((agent) => agent.email === listing.team_lead)
  ).length;

  const isListings = listings.filter((listing) =>
    agents.some((agent) => agent.email === listing.team_lead)
  );

  console.log(totalIsListings);

  const listingWithoutStatus = isListings.filter(
    (listing) => !listing.pipeline_status
  );

  console.log(listingWithoutStatus);

  console.log(agents);
  console.log(listings);

  const setActiveFiltersInArray = (selected) => {
    if (percentageArrays.includes(selected)) {
      setPercentageArrays(percentageArrays.filter((item) => item !== selected));
    } else {
      setPercentageArrays([...percentageArrays, selected]); // Spread operator to create a new array with selected
    }
  };

  const handleSelectAll = () => {
    if (statusPercentages.every((item) => percentageArrays.includes(item))) {
      setPercentageArrays([]);
      return;
    }
    setPercentageArrays(statusPercentages);
  };

  const filteredAccounts = isListings.filter((account) =>
    percentageArrays.includes(account.pipeline_status)
  );

  console.log(filteredAccounts);

  const handleUpdateListingsWithoutStatus = async () => {
    const updatedListings = listingWithoutStatus.map(async (listing) => {
      const updatedListing = {
        ...listing,
        pipeline_status: "10%",
      };

      await setDocData("listings", listing.id, updatedListing);
    });
    updatedListings();
  };

  return (
    <div className="page relative">
      <button className="close-button" onClick={() => setViewMode(false)}>
        Close
      </button>
      {/* <button
        className="blue-button"
        onClick={handleUpdateListingsWithoutStatus}
      >
        Update listings without Status
      </button> */}
      <div className="header">IS Accounts per Agent</div>

      <div className="mt-2">
        <div className="flex items-center gap-4">
          <div
            className={
              statusPercentages.every((item) => percentageArrays.includes(item))
                ? "active-pill"
                : "inactive-pill"
            }
            onClick={handleSelectAll}
          >
            All
          </div>
          {statusPercentages.map((status, index) => (
            <div
              key={index}
              className={
                percentageArrays.includes(status)
                  ? "active-pill"
                  : "inactive-pill"
              }
              onClick={() => setActiveFiltersInArray(status)}
            >
              {status}
            </div>
          ))}
        </div>
      </div>

      <div className="flex items-center gap-4 border-t border-gray-200 w-fit p-2">
        <div className="font-bold w-36">Name</div>
        <div className="font-bold w-36">Number of Accounts(Lead)</div>
        <div className="font-bold w-36">Accounts Agent is a Team Member</div>
      </div>
      {/* create a table where it will show number of listings per agent that he is a team_member */}
      {agents.map((agent, index) => (
        <div
          className="flex items-center gap-4 border-t border-gray-200 w-fit p-2"
          key={index}
        >
          <div className="w-36">{agent.displayName}</div>
          <div className="text-right font-bold w-12">
            {
              filteredAccounts.filter(
                (listing) => listing.team_lead === agent.email
              ).length
            }
          </div>

          <div className="text-right font-bold w-36">
            {
              filteredAccounts.filter((listing) =>
                listing.team_members.includes(agent.email)
              ).length
            }
          </div>
        </div>
      ))}
      <div className="w-36 font-bold mt-4">
        Total IS Accounts {filteredAccounts.length}
      </div>
    </div>
  );
}
