import React from "react";
import InputBox from "../../components/InputBox";
import Button from "../../components/Button";
import { useState } from "react";
import Modal from "../../components/OldModal";
import SpinnerPage from "../../components/SpinnerPage";
import { addNewDoc } from "../../Firebase/Firebase.utils";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Select } from "@mui/material";
import SelectBox from "../../components/SelectBox";
import SelectInput from "@mui/material/Select/SelectInput";
import ReactSelectBox from "../../components/ReactSelectBox";

function SaleListing() {
  const currentUser = useSelector((state) => state.userObject);
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [docId, setDocId] = useState("");
  const [listingType, setListingType] = useState("For Sale");
  const [category, setCategory] = useState("");

  const navigate = useNavigate();

  const categories = [
    { value: "residential house and lot", label: "Residential House and Lot" },
    { value: "condominium", label: "Condominium" },
    { value: "industrial", label: "Industrial" },
    { value: "commercial", label: "Commercial" },
    { value: "raw land", label: "Raw Land" },
    { value: "hotels and resorts", label: "Hotels and Resorts" },
    { value: "office", label: "Office" },
  ];

  //handle change to get form data from input fiels
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setShowModal(true);
  };

  const handleConfirmation = () => {
    setShowSpinner(true);
    const docObject = {
      ...formData,
      listing_type: listingType,
      created_at: new Date(),
      created_by: currentUser.email,
      uid: currentUser.uid,
      team_members: [currentUser.email],
      team_lead: currentUser.email,
      tasks: [],
      files: {},
      selling_price: 0,
      commission_rate: 0,
      zonal: 0,
      appraisal: 0,
      meeting_notes: [],
      marketing_tracker: [],
      potential_fee: 0,
    };

    const addNewDocData = async () => {
      try {
        const res = await addNewDoc("listings", docObject);
        const id = res.id;
        setShowSpinner(false);
        setShowModal(false);
        navigate(`/listing/${id}`);
      } catch (error) {
        setShowSpinner(false);
        setShowModal(false);
      }
    };
    addNewDocData();
  };

  const handleCancel = () => {
    setShowModal(false);
  };

  const handleSelect = (e) => {
    setListingType(e.value);
  };

  const handleSelectCategory = (selected) => {
    setCategory(selected.value);
  };

  const listingTypes = [
    "For Sale",
    "For JV",
    "For Lease",
    "For Sale or Lease",
  ].map((type) => {
    return {
      value: type,
      label: type,
    };
  });

  console.log(listingType);

  return (
    <>
      <form className="flex  flex-col gap-4 flex-wrap" onSubmit={handleSubmit}>
        <h4>Basic Listing Information</h4>
        <div className="flex flex-wrap gap-4">
          <InputBox
            label="Property Name (required)"
            placeholder="e.g. Centro Salcedo Property"
            name="property_name"
            required
            onChange={handleChange}
          />

          <ReactSelectBox
            label="Category"
            options={categories}
            name="category"
            required
            value={categories.find((cat) => cat.value === category)}
            onChange={handleSelectCategory}
          />

          <ReactSelectBox
            label="Listing Type"
            options={listingTypes}
            onChange={handleSelect}
            name="listing_type"
            required
            value={listingTypes.find((type) => type.value === listingType)}
          />

          <InputBox
            label="Listing Description (required)"
            placeholder="e.g. Property along Ayala Avenue"
            name="listing_description"
            required
            onChange={handleChange}
          />

          <InputBox
            label="Address (required)"
            placeholder="e.g. 1234 Ayala Avenue, Makati City"
            name="address"
            required
            onChange={handleChange}
          />

          <InputBox
            label="Google maps pin"
            placeholder="Enter google maps pin link"
            name="google_maps_pin"
            required
            onChange={handleChange}
          />

          <InputBox
            label="Contact Person"
            placeholder="e.g. Enter contact person"
            name="contact_person"
            onChange={handleChange}
          />

          <InputBox
            label="Contact Email"
            placeholder="e.g. Enter contact email"
            name="contact_email"
            onChange={handleChange}
          />
        </div>
        <Button type="submit" label="Create Listing" />
      </form>
      {showModal && (
        <Modal
          header="Listing to be created"
          text="Are you sure you want to create this listing?"
        >
          <Button label="Submit" onClick={handleConfirmation} />
          <Button label="Cancel" onClick={handleCancel} />
        </Modal>
      )}
      {showSpinner && <SpinnerPage />}
    </>
  );
}

export default SaleListing;
