import React from "react";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { getAllDocs } from "../../Firebase/Firebase.utils";
import InputBoxSelect from "../../components/InputBoxSelect";
import IsPipelineListComponent from "./IsPipelineListComponent";
import PageModal from "../../components/PageModal";
import IsAccountsPerAgent from "../Listing/IsAccountsPerAgent";

export default function IsPipeline() {
  const navigate = useNavigate();
  const [agents, setAgents] = useState([]);
  const [agentsOptions, setAgentsOptions] = useState([]); // [ { value: "agent.email", label: "displayName" }
  const [loading, setLoading] = useState(true);
  const [selectedAgent, setSelectedAgent] = useState(null);
  const [viewMode, setViewMode] = useState(false);

  useEffect(() => {
    const fetchAgents = async () => {
      const users = await getAllDocs("users");
      //filter user roles to agents only
      const agents = users.filter((user) => user.roles?.includes("agent"));
      const isAgents = agents.filter(
        (agent) => agent.department === "Investment Sales"
      );

      const options = isAgents.map((agent) => {
        return {
          value: agent.email,
          label: agent.displayName,
        };
      });

      //sort agents by displayName
      options.sort((a, b) => (a.label > b.label ? 1 : -1));
      setAgentsOptions(options);
      setAgents(isAgents);
      setLoading(false);
    };

    fetchAgents();
  }, []);

  const handleNavigate = () => {
    navigate("/dashboard");
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  const handleSelectAgent = (agent) => {
    setSelectedAgent(agent);
  };

  const handleViewSummary = () => {
    setViewMode(!viewMode);
  };

  return (
    <div className="text-gray-600">
      <button className="mb-2 underline text-sm" onClick={handleNavigate}>
        Back to Dashboard
      </button>

      <div>
        <button className="blue-button mb-4" onClick={handleViewSummary}>
          See Summary Report
        </button>
      </div>
      <div>
        <div className="p-2 rounded-xl bg-white">
          <div className="mb-2 font-bold">
            Investment Sales Pipeline per Agent
          </div>
          <div>
            <InputBoxSelect
              label="Select an Agent"
              options={agentsOptions}
              placeholder="Select Agent"
              onChange={handleSelectAgent}
            />
          </div>
        </div>
        <div className="mt-4">
          {selectedAgent && (
            <IsPipelineListComponent selectedAgent={selectedAgent} />
          )}
        </div>
      </div>

      {viewMode && (
        <PageModal>
          <IsAccountsPerAgent setViewMode={setViewMode} />
        </PageModal>
      )}
    </div>
  );
}
