import React, { useState } from "react";
import UseGetAllDoc from "../Hooks/UseGetAllDoc";
import PipelineCard from "./PipelineCard";
import { useEffect } from "react";
import { getAllDocsSnap, setDocData } from "../Firebase/Firebase.utils";
import { AnimatePresence, motion } from "framer-motion";
import PageModal from "../components/PageModal";
import SummaryReport from "./SummaryReport";
import { useNavigate } from "react-router-dom";
import InputBoxSelect from "../components/InputBoxSelect";
import InputBox from "../components/InputBox";

export default function CLPipeline({ fullScreen }) {
  const statusPercentages = ["10%", "30%", "50%", "90%", "100%"];
  const navigate = useNavigate();
  const filters = ["active", "inactive"];
  const [accounts, setAccounts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [activeFilter, setActiveFilter] = useState("active");
  const [percentageArrays, setPercentageArrays] = useState(statusPercentages);
  const [searchTerm, setSearchTerm] = useState("");
  const [viewMode, setViewMode] = useState(false);
  const [landlordRep, setLandlordRep] = useState([]);
  const [tenantRep, setTenantRep] = useState([]);
  const [fullScreenMode, setFullScreenMode] = useState(fullScreen || false);
  const [agents, setAgents] = useState([]);
  const [agent, setAgent] = useState(null);

  useEffect(() => {
    const fetchAgents = async () => {
      await getAllDocsSnap("users", (data) => {
        console.log(data);
        const filteredUsers = data.filter(
          (user) =>
            user.department === "Commercial Leasing" &&
            user.roles.includes("agent")
        );

        //sort alphabetically
        filteredUsers.sort((a, b) => {
          if (a.email < b.email) {
            return -1;
          }
          if (a.email > b.email) {
            return 1;
          }
          return 0;
        });

        setAgents(filteredUsers);
        console.log(filteredUsers);
      });
    };
    fetchAgents();
  }, []);

  useEffect(() => {
    const fetchLandlordRep = async () => {
      await getAllDocsSnap("landlordRepRequirements", (data) => {
        const mappedData = data.map((item) => {
          return {
            accountType: "Landlord Rep",
            ...item,
          };
        });
        setLandlordRep(mappedData);
      });
    };
    fetchLandlordRep();
  }, []);

  useEffect(() => {
    const fetchAccounts = async () => {
      await getAllDocsSnap("tenantRep", (data) => {
        const mappedData = data.map((item) => {
          return {
            accountType: "Tenant Rep",
            ...item,
          };
        });
        setTenantRep(mappedData);
        setIsLoading(false);
      });
    };
    fetchAccounts();
  }, []);

  //concatenate landlordRep and tenantRep
  const allAccounts = [...landlordRep, ...tenantRep];

  console.log(allAccounts);

  const [activePercentage, setActivePercentage] = React.useState("All");

  if (isLoading) {
    return <div>Loading...</div>;
  }

  //arrange accounts alphabetically using tenantName
  allAccounts.sort((a, b) => {
    if (a.tenantName < b.tenantName) {
      return -1;
    }
    if (a.tenantName > b.tenantName) {
      return 1;
    }
    return 0;
  });

  const currentList = allAccounts.filter(
    (account) => account.status === activeFilter
  );

  const filteredAccounts = currentList.filter(
    (account) =>
      percentageArrays.includes(account.pipeline_status) &&
      account.tenantName.toLowerCase().includes(searchTerm.toLowerCase()) &&
      (agent ? account.team_lead === agent : true)
  );

  console.log(filteredAccounts.length);

  const setActiveFiltersInArray = (selected) => {
    if (percentageArrays.includes(selected)) {
      setPercentageArrays(percentageArrays.filter((item) => item !== selected));
    } else {
      setPercentageArrays([...percentageArrays, selected]); // Spread operator to create a new array with selected
    }
  };

  const handleSelectAll = () => {
    if (statusPercentages.every((item) => percentageArrays.includes(item))) {
      setPercentageArrays([]);
      return;
    }
    setPercentageArrays(statusPercentages);
  };

  const handleViewReport = () => {
    setViewMode(!viewMode);
  };

  const handleNavigate = (type, id) => {
    if (type === "Landlord Rep") {
      navigate(
        `/leasing/landlordrepresentation/${id}?link=Requirement+Details`
      );
    } else {
      navigate(`/leasing/tenantRep/req/${id}?link=Requirement+Details`);
    }
  };

  const toggleActiveStatus = async (type, id) => {
    const account = allAccounts.find((account) => account.id === id);
    const status = account.status === "active" ? "inactive" : "active";
    await setDocData(
      type === "Landlord Rep" ? "landlordRepRequirements" : "tenantRep",
      id,
      { status }
    );
  };

  const handleFullScreen = () => {
    setFullScreenMode(!fullScreenMode);
  };

  const agentOptions = agents.map((agent) => {
    return {
      value: agent.email,
      label: agent.email
        .replace("@leechiu.com", "")
        .replace(".", " ")
        .toUpperCase(),
    };
  });

  const newAgentOptions = [
    {
      value: null,
      label: "All Agents",
    },
    ...agentOptions,
  ];

  return (
    <div className="">
      <div>
        <div className="header">Commercial Leasing Pipeline</div>
        <button className="blue-button" onClick={handleViewReport}>
          See Summary Report
        </button>
      </div>

      <InputBoxSelect
        options={newAgentOptions}
        value={newAgentOptions.filter((option) => option.value === agent)}
        onChange={(e) => setAgent(e.value)}
        defaultValue="Select Agent"
      />

      <div>
        <div className="text-sm uppercase font-bold text-gray-600 mt-2">
          Total Accounts: {filteredAccounts.length}
        </div>
        <div className="flex items-start gap-4 lg:flex-row flex-col lg:items-center">
          <div className="mt-2 links-container">
            {filters.map((filter, index) => (
              <div
                key={index}
                className={`capitalize ${
                  activeFilter === filter
                    ? "activeLinkStyle"
                    : "inactiveLinkStyle"
                }`}
                onClick={() => setActiveFilter(filter)}
              >
                {filter}
              </div>
            ))}
          </div>
          <div className="mt-2">
            <div className="flex items-center gap-4">
              <div
                className={
                  statusPercentages.every((item) =>
                    percentageArrays.includes(item)
                  )
                    ? "active-pill"
                    : "inactive-pill"
                }
                onClick={handleSelectAll}
              >
                All
              </div>
              {statusPercentages.map((status, index) => (
                <div
                  key={index}
                  className={
                    percentageArrays.includes(status)
                      ? "active-pill"
                      : "inactive-pill"
                  }
                  onClick={() => setActiveFiltersInArray(status)}
                >
                  {status}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      {!fullScreenMode && (
        <div className="mt-4">
          <button className="blue-button" onClick={handleFullScreen}>
            See Full Screen
          </button>
        </div>
      )}

      <div className="mt-4">
        <InputBox
          name="search"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder={"Search Here"}
        />
      </div>

      <div className="mt-4">
        {filteredAccounts.map((account, index) => (
          <div
            key={index}
            className="card mt-2 flex flex-col lg:flex-row items-start gap-1 lg:gap-2 flex-wrap relative pt-8"
          >
            <div
              className={`rounded-full px-2 py-1 text-xs w-fit text-white mb-2 absolute top-0 -left-8 ${
                account.accountType === "Landlord Rep"
                  ? "bg-leechiuBlue"
                  : "bg-leechiuOrange"
              }`}
            >
              {account.accountType === "Landlord Rep" ? "LR" : "TR"}
            </div>

            <button
              className={`absolute top-0 right-2 capitalize  ${
                account.status === "active"
                  ? "bg-leechiuOrange w-fit px-1 rounded-full text-white"
                  : "bg-gray-300 w-fit px-1 rounded-full text-gray-600"
              }`}
              onClick={() =>
                toggleActiveStatus(account.accountType, account.id)
              }
            >
              {account.status}
            </button>

            <div className="w-full lg:w-36 flex flex-row lg:flex-col items-start gap-2 lg:gap-0">
              <div className="card-header">Client</div>
              <div className="font-bold text-leechiuOrange">
                {account.tenantName}
              </div>
            </div>
            <div className="w-full lg:w-36 flex flex-row lg:flex-col items-start gap-2 lg:gap-0">
              <div className="card-header">Industry</div>
              <div>{account.tenantIndustry}</div>
            </div>
            <div className="w-full lg:w-36 flex flex-row lg:flex-col items-start gap-2 lg:gap-0">
              <div className="card-header">Lead</div>
              <div className="capitalize">
                {account.team_lead
                  ? account?.team_lead
                      .replace("@leechiu.com", "")
                      .replace(".", " ")
                  : "Not Indicated"}
              </div>
            </div>
            <div className="w-full lg:w-36 flex flex-row lg:flex-col items-start gap-2 lg:gap-0">
              <div className="card-header">Source</div>
              <div>{account.leadSource}</div>
            </div>
            <div className="w-full lg:w-36 flex flex-row lg:flex-col items-start gap-2 lg:gap-0">
              <div className="card-header">Type</div>
              <div>{account.requirementType}</div>
            </div>
            <div className="w-full lg:w-36 flex flex-row lg:flex-col items-start gap-2 lg:gap-0">
              <div className="card-header">Building</div>
              <div>{account.buildingName || "Not Indicated"}</div>
            </div>
            <div className="w-full lg:w-36 flex flex-row lg:flex-col items-start gap-2 lg:gap-0">
              <div className="card-header">Location</div>
              <div>{account.requirementLocation || "Not Indicated"}</div>
            </div>
            <div className="w-full lg:w-36 flex flex-row lg:flex-col items-start gap-2 lg:gap-0">
              <div className="card-header">Forecast Billing Date</div>
              <div>{account.billingDate || "Not Indicated"}</div>
            </div>
            <div className="w-full lg:w-36 flex flex-row lg:flex-col items-start gap-2 lg:gap-0">
              <div className="card-header">Size</div>
              <div>{account.requirementSize || "Not Indicated"} sqm</div>
            </div>
            <div className="w-full lg:w-36 flex flex-row lg:flex-col items-start gap-2 lg:gap-0">
              <div className="card-header">Budget</div>
              <div>{account.requirementBudget || "Not Indicated"}</div>
            </div>
            <div className="w-full lg:w-36 flex flex-row lg:flex-col items-start gap-2 lg:gap-0">
              <div className="card-header">Fee</div>
              <div>{account.potentialFee || "Please indicate in details"}</div>
            </div>
            <div className="w-full lg:w-36 flex flex-row lg:flex-col items-start gap-2 lg:gap-0">
              <div className="card-header">Status %</div>
              <div>
                {account.pipeline_status || "Please indicate in details"}
              </div>
            </div>
            <div>
              <button
                className="underline absolute bottom-0 right-1"
                onClick={() => handleNavigate(account.accountType, account.id)}
              >
                See Details
              </button>
            </div>
          </div>
        ))}
      </div>

      {viewMode && (
        <PageModal>
          <SummaryReport setViewMode={setViewMode} data={allAccounts} />
        </PageModal>
      )}

      {fullScreenMode && !fullScreen && (
        <PageModal>
          <div className="bg-gray-100 rounded-lg w-full pl-16 py-8 min-h-screen">
            <button
              className="underline mb-2"
              onClick={() => setFullScreenMode(false)}
            >
              Close Full Screen
            </button>
            <CLPipeline fullScreen={fullScreenMode} />
          </div>
        </PageModal>
      )}
    </div>
  );
}
