import React from "react";
import { Link, useParams } from "react-router-dom";
import useGetDocData from "../Hooks/useGetDocData";
import RequirementHeader from "./RequirementHeader";
import { useSearchParams } from "react-router-dom";
import RequirementDetails from "./RequirementDetails";
import { useNavigate } from "react-router-dom";
import TeamMembers from "./TeamMembers";
import { setDocData } from "../Firebase/Firebase.utils";
import UseGetAllDoc from "../Hooks/UseGetAllDoc";
import toast from "react-hot-toast";
import CompanyProfile from "./CompanyProfile";
import TeamNotes from "./TeamNotes";
import TaskManagement from "./TaskManagement";

export default function LandLordRepDetails() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchTerm, setSearchTerm] = React.useState("");
  const [isSearching, setIsSearching] = React.useState(false);

  const activeLinkParam = searchParams.get("link");

  const { data: requirement, isLoading } = useGetDocData({
    collection: "landlordRepRequirements",
    id,
  });

  const { data: tenantRepRequirements, isLoading: isTenantRepLoading } =
    UseGetAllDoc({ collection: "tenantRep" });

  if (isTenantRepLoading) {
    return <div>Loading...</div>;
  }

  if (isLoading) {
    return <div>Loading</div>;
  }

  const handleBack = () => {
    window.history.back();
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    setIsSearching(true);
  };

  const blurSearch = () => {
    setIsSearching(false);
  };

  const handleSelect = async (tenantRep) => {
    console.log(tenantRep);
    const docObject = {
      tenantRepLink: tenantRep.id,
      tenantRepName: tenantRep.tenantName,
    };

    console.log(docObject);
    await setDocData("landlordRepRequirements", requirement.id, docObject);
    toast.success("Tenant Rep Requirement linked");
    setSearchTerm("");
    setIsSearching(false);
  };

  return (
    <div>
      <div>
        <div className="underline text-xs text-gray-600 mb-8">
          <button className="underline" onClick={handleBack}>
            Back
          </button>
        </div>
      </div>

      <div>
        <RequirementHeader requirement={requirement} />
      </div>

      <div>
        <div className="font-bold text-gray-600 uppercase text-xs mt-4">
          Link a tenant rep requirement
        </div>
        <div>
          Linked to:{" "}
          {requirement.tenantRepLink ? requirement.tenantRepName : ""}
        </div>
        <input
          className="px-2 py-1 border border-gray-300 rounded-lg w-full"
          type="search"
          placeholder="search for tenant rep requirement"
          onChange={handleSearch}
          onBlur={blurSearch}
          value={searchTerm}
        />

        {searchTerm.length > 0 && (
          <div className="max-h-36 p-2 shadow-md rounded-lg w-[350px] overflow-scroll absolute bg-white border border-gray-100 ">
            {tenantRepRequirements
              .filter((tenantRep) =>
                tenantRep.tenantName
                  .toLowerCase()
                  .includes(searchTerm.toLowerCase())
              )
              .map((tenantRep, index) => (
                <div
                  key={index}
                  className="p-2 border-b border-gray-100 cursor-pointer hover:bg-gray-100 capitalize"
                  onClick={() => handleSelect(tenantRep)}
                >
                  <div>{tenantRep.tenantName}</div>
                </div>
              ))}
          </div>
        )}
      </div>

      <div className="mt-8">
        {activeLinkParam === "Requirement Details" && (
          <div className="space-y-8">
            <RequirementDetails requirementData={requirement} />

            <TeamMembers requirementData={requirement} />
          </div>
        )}

        {activeLinkParam === "Company Profile" && (
          <div>
            <CompanyProfile requirementData={requirement} />
          </div>
        )}

        {activeLinkParam === "Team Notes" && (
          <div>
            <TeamNotes requirementData={requirement} />
          </div>
        )}

        {activeLinkParam === "Tasks" && (
          <div>
            <TaskManagement requirementData={requirement} id={id} />
          </div>
        )}
      </div>
    </div>
  );
}
